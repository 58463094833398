import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Hub } from 'aws-amplify/utils';
import { Context } from '@context/Context';
import { AppContext } from '@interfaces';

import { setUserType, setUser, setStatusFilter } from '@context/actions';
import HeliosClient from '@api/HeliosClient';
import { configureAuth } from '@utils/amplify';
import { DASHBOARD_SECTION } from '@utils/constants';
import { ROLE } from '@utils/constants';
import { fetchSituationTypes } from '@utils/ServiceType.utils';

export const useAuthListener = () => {
	const { dispatch } = useContext(Context) as AppContext;
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		if (!location.pathname.includes('/login') && !location.pathname.includes('/callback')) {
			return;
		}
		const userType = location.pathname.split('/login/')[1] || 'admin';
		configureAuth(userType);

		const handleSignedInHub = async () => {
			try {
				const response = await new HeliosClient().clientLoginWithToken();
				if (response.data.status) {
					const userData = response.data.data.user;
					const userType = userData.type || 'user';

					setUserType(dispatch, userType);
					setUser(dispatch, userData);
					const adminRole = userData.roles.find((role: string) => role === ROLE.ADMIN_EOS);
					if (adminRole) setStatusFilter(dispatch, DASHBOARD_SECTION.BILLING);
					await fetchSituationTypes(dispatch, userData?.branch);
					history.push('/dashboard');
				}
			} catch (error) {
				console.error('Error handling signed in hub:', error);
			}
		};

		const hubListener = Hub.listen('auth', async ({ payload }) => {
			if (payload.event === 'signedIn' && userType === 'admin') {
				await handleSignedInHub();
			}
		});

		return () => {
			hubListener();
		};
	}, [location]);
};
